//scss
import './../scss/style.scss';
import './../scss/element.scss';
import './../scss/media.scss';

// Script

import 'bootstrap';


$('.js-select-single').select2({
    minimumResultsForSearch: Infinity,
    width: '100%'
});

// burger
$(document).ready(function(){
	$('.nav-icon').click(function(){
        $(this).toggleClass('open');
        $('.menu_list').toggleClass('open');
        $('.menu_block ul').toggleClass('open');
	});
});



// Data table script
$(document).ready( function () {
    $('#table_id').DataTable({
        filter: false,
        info: false,
        "autoWidth": false,
        "lengthChange": false,
    });
} );



// Script Modal

$('.js_entrance').click(function () {
    $('.entrance_block').css({'display':'block'});
    $('.regis_block').css({'display':'none'});
    $('.js_regis').removeClass('active');
    $('.js_entrance').addClass('active');
});
$('.js_regis').click(function () {
    $('.entrance_block').css({'display':'none'});
    $('.regis_block').css({'display':'block'});
    $('.js_entrance').removeClass('active');
    $('.js_regis').addClass('active');
});

$('.js_open_modal').click(function(){
    $('.modal_register').addClass('open');
});
$('.modal_register .close_window, .modal_register .close').click(function(){
    $('.modal_register').removeClass('open');
});



// Cabinet position

$('.cabinet_block li').click(function() {
    $('.cabinet_block li').removeClass('active');
    $(this).addClass('active');

    $('.setting_block').css({'display': 'none'});
    $(`.setting_block.setting_${$(this).val()}`).css({'display': 'block'});
});

